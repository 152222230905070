import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PropTypes from 'prop-types';

import AppTile from '../components/AppTile';
import HeroSection from '../components/HeroSection';
import { CommonContext } from '../context';

export const query = graphql`
  query homePageDataQuery {
    allAppList(filter: { isFeatured: { eq: true } }) {
      nodes {
        isFeatured
        appName
        description
        theme
        route
        font
        previewImageFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        downloadLinks {
          playStore
          huawei
          appStore
          mac
          windows
          web
          linux
        }
      }
    }
    allHomePageData {
      nodes {
        heroSection {
          title
          subTitle
          theme
          textColor
          link {
            text
            url
          }
          image
        }
      }
    }
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { ne: "archived" } } } }
      }
      limit: 1
    ) {
      nodes {
        title
        slug
      }
    }
  }
`;

function IndexPage(props) {
  const node = props.data.allHomePageData.nodes[0];
  const heroSection = node.heroSection;
  const featuredApps = props.data.allAppList.nodes;
  const latestBlogPost = props.data.allWpPost.nodes[0];
  return (
    <CommonContext.Provider value={{ location: props.location }}>
      <Layout
        keywords={[`greentech`, `islam`, `non-profit`, `muslims`]}
        title="Home"
        location={props.location}
      >
        {featuredApps.map((item, index) => {
          item.imageFile = item.previewImageFile;
          return <AppTile key={index} index={index} appData={item} />;
        })}
        <div className="gap-0 md:grid md:grid-cols-2">
          {heroSection.map((item) => {
            if (item.title === "What's New?") {
              item.subTitle = latestBlogPost.title;
              item.link.url = 'blog/' + latestBlogPost.slug;
            }
            return <HeroSection key={item.title} data={item} />;
          })}
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default IndexPage;
